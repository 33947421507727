export const WHITELABEL_GOVERNORS_IDS = {
  'vote.nicotourne.com': "nounsdao",
  'vote-staging.nicotourne.com': "hai-testnet",
  'dao-staging.nicotourne.com': "tally-test-dao-sepolia",
  'gov-staging.letsgethai.com': "hai-testnet",
  'gov-sepolia.letsgethai.com': "hai-sepolia",
  'gov-staging.opendollar.com': "opendollar-testnet",
  'localhost:3001': "hai",
  'gov-staging.tally.xyz': "tdao",
  'gov.opendollar.com': "opendollar",
  'gov.letsgethai.com': "hai",
  'vote.zksync.io': "zksync",
  'delegate.zksync.io': "zksync",
  'vote.zknation.io': "zksync",
  'delegate.zknation.io': "zksync",
  'tally.ensdao.org': "ens",
  'gov.adventuregold.org': "agld",
  'vote.obol.org': "obol",
  'vote.gaianet.ai': "gaianet",
}