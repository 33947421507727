/* Autogenerated file. Do not edit manually. */
/* eslint-disable */

// Record to same the specific copy label of different Organizations
const organizationLabels: Record<string, Record<string, string>> = {
    "2413388957975839812": {
        "Active delegate": "Seeking delegation",
        "Active delegates": "Seeking delegations",
        "Inactive delegate": "Not seeking delegation",
    },
}

export default organizationLabels