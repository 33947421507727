import {
  arbitrum,
  base,
  avalanche,
  bsc,
  gnosis,
  sepolia,
  mainnet,
  optimism,
  polygon,
  bscTestnet,
  avalancheFuji,
  arbitrumNova,
  zkSync,
  moonbeam,
  linea,
  lineaTestnet,
  mantle,
  pulsechain,
  polygonZkEvm,
  polygonZkEvmTestnet,
} from "@wagmi/chains"

import {
  chainIdToChainReference,
  chainReferenceToChainId,
} from "web3/helpers/transformers"
import chains, { ChainId } from "web3/constants/chains"

const WC_SUPPORTED_CHAINS_EXTRA = [ChainId.Scroll, ChainId.CeloMainnet].map(
  chainIdToChainReference,
)

const WC_SUPPORTED_CHAINS = [
  mainnet.id,
  arbitrum.id,
  base.id,
  zkSync.id,
  linea.id,
  avalanche.id,
  bsc.id,
  gnosis.id,
  polygon.id,
  optimism.id,
  arbitrumNova.id,
  moonbeam.id,
  mantle.id,
  pulsechain.id,
  polygonZkEvm.id,
  ...WC_SUPPORTED_CHAINS_EXTRA,
]

const WC_SUPPORTED_CHAINS_TESTNET_EXTRA = [
  ChainId.ArbitrumSepoliaRollupTestnet,
  ChainId.ZKsyncSepoliaTestnet,
  ChainId.BaseSepoliaTestnet,
  ChainId.ScrollSepoliaTestnet,
  ChainId.MantleSepoliaTestnet,
].map(chainIdToChainReference)

const WC_SUPPORTED_CHAINS_TESTNET = [
  bscTestnet.id,
  sepolia.id,
  avalancheFuji.id,
  lineaTestnet.id,
  polygonZkEvmTestnet.id,
  ...WC_SUPPORTED_CHAINS_TESTNET_EXTRA,
]

const isChainSupported = (chainId: string, chains: number[]) => {
  const wcFound = chains.find(
    (wcChainId) => chainReferenceToChainId(wcChainId) === chainId,
  )

  return Boolean(wcFound)
}

const getChainOrder = (chainId: string, chains: number[]) => {
  const indexFound = chains.findIndex(
    (wcChainId) => chainReferenceToChainId(wcChainId) === chainId,
  )

  return indexFound
}

export const getWalletConnectSupportedChains = () => {
  const supportedChains = Object.entries(chains)
    .filter(([_, chain]) => isChainSupported(chain.id, WC_SUPPORTED_CHAINS))
    .map(([_, chain]) => ({ ...chain }))
    .sort((chainA, chainB) =>
      getChainOrder(chainA.id, WC_SUPPORTED_CHAINS) <
      getChainOrder(chainB.id, WC_SUPPORTED_CHAINS)
        ? -1
        : 1,
    )

  return supportedChains
}

export const getWalletConnectSupportedTestnetChains = () => {
  const supportedChains = Object.entries(chains)
    .filter(([_, chain]) =>
      isChainSupported(chain.id, WC_SUPPORTED_CHAINS_TESTNET),
    )
    .map(([_, chain]) => ({ ...chain }))
    .sort((chainA, chainB) =>
      getChainOrder(chainA.id, WC_SUPPORTED_CHAINS_TESTNET) <
      getChainOrder(chainB.id, WC_SUPPORTED_CHAINS_TESTNET)
        ? -1
        : 1,
    )

  return supportedChains
}
