import type { FC } from "react"
import { useRef, useEffect, useState } from "react"
import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { isMobile } from "react-device-detect"

import MarkdownViewer from "common/components/MarkdownViewer"
import LoadMoreButton from "common/components/LoadMoreButton"
import {
  useDelegateStatementQuery,
  DelegateStatementSource,
} from "query/graphql"
import InformationIcon from "ui/components/icons/InformationIcon"
import CardContainer from "common/components/CardContainer"
import { isSameAddress } from "web3/helpers/address"
import { useMe } from "user/providers/MeProvider"
import { ROUTES } from "common/constants/routes"
import GreenCircleCheck from "ui/components/icons/GreenCircleCheck"
import { Ban } from "ui/components/icons/font-awesome/Ban"
import { useIsWhiteLabel } from "whitelabel/hooks/useIsWhiteLabel"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { getOrgCustomLabel } from "organization/helpers/org-custom-label"

type Props = {
  voterAddress: string
  organizationId: string
  governorSlug: string
  governorName?: string
  displayEditButton?: boolean
}

const AddressDelegationStatement: FC<Props> = ({
  voterAddress,
  organizationId,
  governorSlug,
  governorName,
  displayEditButton = true,
}) => {
  const me = useMe()
  const [startingHeight, setStartingHeight] = useState(isMobile ? 80 : 200)
  const [displayLoadMore, setDisplayLoadMore] = useState(true)
  const [show, setShow] = useState(false)

  const { data } = useDelegateStatementQuery(
    {
      input: {
        address: voterAddress,
        organizationId,
      },
    },
    {
      enabled: Boolean(voterAddress) && Boolean(organizationId),
    },
  )

  const statement = data?.delegate?.statement

  const isWhiteLabel = useIsWhiteLabel()

  const markdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      markdownRef &&
      markdownRef.current &&
      markdownRef.current.clientHeight > 0
    ) {
      if (markdownRef.current.clientHeight < startingHeight) {
        setStartingHeight(markdownRef.current.clientHeight)
        setDisplayLoadMore(false)
      }
    }
  }, [statement, startingHeight])

  const isMeProfile = me && isSameAddress(me.address, voterAddress)

  if (!data?.delegate) return null

  const issues = statement?.issues ?? []

  const handleToggleLoadMore = () => {
    setShow(!show)
  }

  return (
    <CardContainer
      isTableView
      title={
        governorName
          ? `${governorName} Delegation Statement`
          : `Delegation Statement`
      }
      topRight={
        isMeProfile && displayEditButton ? (
          <Flex align="center" flex={1} pt={{ base: 2, sm: 0 }} width="100%">
            <Link
              href={
                isWhiteLabel
                  ? getWhiteLabelRoute(
                      ROUTES.governance.delegate.edit(
                        governorSlug,
                        ROUTES.governance.delegate.profile(
                          governorSlug,
                          voterAddress,
                        ),
                      ),
                    )
                  : ROUTES.governance.delegate.edit(
                      governorSlug,
                      ROUTES.governance.delegate.profile(
                        governorSlug,
                        voterAddress,
                      ),
                    )
              }
              width="100%"
            >
              <Button size="sm" variant="secondary" width="100%">
                Edit
              </Button>
            </Link>
          </Flex>
        ) : null
      }
    >
      {statement ? (
        <>
          <Collapse in={show} startingHeight={startingHeight}>
            <Stack ref={markdownRef} px={5} py={2} spacing={2}>
              {statement?.isSeekingDelegation ? (
                <Flex align="center">
                  <Icon as={GreenCircleCheck} mr={2} />
                  <Text as="span" color="green.600" textStyle="md">
                    {getOrgCustomLabel(organizationId, "Active delegate")}
                  </Text>
                </Flex>
              ) : (
                <Flex align="center">
                  <Icon as={Ban} fill="gray.500" mr={2} />
                  <Text as="span" color="gray.500" textStyle="md">
                    {getOrgCustomLabel(organizationId, "Inactive delegate")}
                  </Text>
                </Flex>
              )}

              {issues?.length ? (
                <Flex display="inline-flex" flexWrap="wrap">
                  {issues?.map((issue) => (
                    <Flex
                      key={`delegation_statement_issue_${issue.id}`}
                      align="center"
                      justify="space-between"
                      mb={2}
                      mr={2}
                    >
                      <HStack
                        align="center"
                        bg="purple.100"
                        borderRadius="md"
                        px={4}
                        py={2}
                      >
                        <Text
                          color="purple.600"
                          fontWeight="bold"
                          textStyle="sm"
                        >
                          {issue.name}
                        </Text>
                      </HStack>
                    </Flex>
                  ))}
                </Flex>
              ) : null}

              <MarkdownViewer content={statement?.statement} />
            </Stack>
            <Stack direction="column" spacing={3} w="full">
              {statement?.dataSourceURL &&
              statement?.dataSource === DelegateStatementSource.Script ? (
                <>
                  <Flex align="center" justify="baseline" pl={4}>
                    <Icon
                      as={InformationIcon}
                      color="gray.500"
                      display={{ base: "none", sm: "block" }}
                      h={4}
                      mr={2}
                      w={4}
                    />
                    <Text color="gray.500">
                      This information was sourced from{" "}
                      <Link
                        isExternal
                        color="purple.500"
                        href={statement?.dataSourceURL}
                      >
                        {statement?.dataSourceURL}
                      </Link>
                    </Text>
                  </Flex>
                </>
              ) : null}
            </Stack>
          </Collapse>

          {displayLoadMore ? (
            <>
              <Box
                backgroundImage={
                  !show
                    ? "linear-gradient(rgba(255, 255, 255, 0.50), rgb(255, 255, 255))"
                    : "none"
                }
                height={!show ? "30px" : "10px"}
                mb={!show ? 6 : 0}
                mt={!show ? -8 : 0}
              />
              <Stack>
                <Divider />
                <Center flex={1} pb={0}>
                  <LoadMoreButton
                    flex={1}
                    isDisabled={false}
                    label={show ? "Show less" : "Show more"}
                    variant="tertiary"
                    onClick={handleToggleLoadMore}
                  />
                </Center>
              </Stack>
            </>
          ) : null}
        </>
      ) : (
        <Flex justify="center" py={6}>
          <Text color="gray.500" textStyle="md">
            No statement provided
          </Text>
        </Flex>
      )}
    </CardContainer>
  )
}
export default AddressDelegationStatement
